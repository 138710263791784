@import url(https://fonts.googleapis.com/css?family=Comfortaa&display=swap);
.player-wrapper {
  position: relative;
  width: 100%;
  min-height: calc((100vw - 20px) * 0.5625 + 10px)!important;
}
.float-header{
  width:100%;
  background: rgb(0,0,0);
}
.top-logo {
  width: 100px;
}
@media(max-width: 1000px) {
  .program-title{
    font-size: 14px!important;
  }
}

@media(min-width: 1000px){
  .player-wrapper{
    min-height: 66vh!important;
  }
  .float-header{
    position: absolute;
    width:100%;
    background: rgb(0,0,0);
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
  }
}

.tool-tip {
  max-width: 300px;
}

.channel {
  min-height: 95px!important;
}

.timeRow {
  min-height: 20px!important;
}

.vod-poster{
    height: 400px;
    width: 300px;
    position: relative;
}

.vod-title {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.badge {
  position: absolute;
  top: -10px;
  left: -10px;
  height: 20px;
  width: 60px;
  text-align: center;
  font-size: 14px;
}

.channelIcon {
  max-width: 50px;
  filter: grayscale(100%) sepia(1) saturate(1) brightness(0%) contrast(101%) hue-rotate(108deg) invert(100%);
  -webkit-filter: grayscale(100%) sepia(1) saturate(0) brightness(35%) contrast(101%) hue-rotate(108deg) invert(100%);
}

.nav-link {
  color: #6ebe44;
  text-decoration: none;
  font-weight: bold;
}

.active {
  text-decoration: underline;
}

.active-stream {
  box-shadow: inset 0 0 1px 2px #6ebe4494;
}

